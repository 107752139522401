li {
  list-style-type: none;
}

p {
  text-align: justify;
}
a {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}
.wrap {
  max-width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.wrap,
.wrap-fuild {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.section {
  padding-bottom: 80px;
  padding-top: 80px;
}
.contact {
  p {
    font-size: 17px;
  }
}
.contact__map iframe {
  margin-bottom: -160px;
}
.section--contactform {
  padding-top: 240px!important;
}
.form-group label {
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
  color: #222;
}
.contact__form .form-control {
  border-bottom: 1px solid #e7e7e7;
}
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--body-font-color);
  padding: 0 0 15px 0;
  background-color: var(--white-color);
  font-family: var(--body-font-family);
  font-size: 18px;
  color: var(--body-font-color);
  font-weight: 500;
}
.btn {
  padding: 8px 24px;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  border-radius: 2px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
  border: 1px solid #000;
  cursor: pointer;
  color: #000 !important;
  text-decoration: none !important;
}
.form-btn .wpcf7-submit {
  background-color: #000;
  color: #fff !important;
}
.section--portfolios .text-dark {
  margin-right: 50%;
}

.portfolios__row .col-md-4 {
  border-left: 1px solid #ffffff69;
  padding: 0 0 0 64px;
}
.catheader__list li {
  position: relative;
  color: white;
}
.catheader__list li + li {
  margin-top: 30px;
}
.catheader__list li a {
  text-transform: uppercase;
  color: #fff !important;
  font-size: 14px;
  letter-spacing: 2px;
  font-family: "Abel", Sans-serif;
}
.text-dark {
  color: #e7e7e782 !important;
}
.catheader__list li + li:before {
  content: "";
  position: absolute;
  left: 0;
  top: -15px;
  width: 40px;
  height: 1px;
  background-color: #ffffff69;
}
.catheader__list li a:hover {
  text-decoration: underline;
  transition: all 0.4s ease;
}

.section--projects h3 {
  font-weight: 500;
}
.btn:hover {
  background-color: #000;
  color: white !important;
}
.projects__image {
  position: relative;
  img {
    width: 100% !important;
    height: 100% !important;
  }
}
.projects__image:before {
  left: 100%;
  width: 60px;
  height: 1px;
  z-index: 5;
}
.projects__image:before,
.projects__image:after {
  content: "";
  position: absolute;
  opacity: 0;
  background-color: #fff;
  transition: all 0.5s ease;
  transform: translate(-50%, -50%);
}
.projects__image a {
  display: block;
  padding: 15px;
  border: 1px solid #c5bebe;
  transition: all 0.5s ease;
}
.projects__image:hover:before,
.projects__image:hover:after {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.projects__image:after {
  top: 0;
  width: 1px;
  height: 60px;
}
.projects__image:before,
.projects__image:after {
  content: "";
  position: absolute;
  opacity: 0;
  background-color: #fff;
  transition: all 0.5s ease;
  transform: translate(-50%, -50%);
}
.projects_image a:hover {
  text-decoration: none;
  color: var(--link-hover-font-color);
}
.projects__image a:hover:after {
  content: "";
  background: linear-gradient(90deg, #222 0%, #444 100%, #666 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  opacity: 0.3;
}
.email {
  color: rgb(172, 163, 163) !important;
  font-size: 14px;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 250px;
}
.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ffffff;
  box-shadow: 0 19px 38px rgb(0 0 0 / 10%), 0 15px 12px rgb(0 0 0 / 2%);
  margin: 5px;
}

.read {
  cursor: pointer;
  border: 1px solid white;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  width: 131px;
  left: 31%;
  position: absolute;
  bottom: 1%;
  visibility: hidden;
}

.read :hover{
  visibility: visible;
}

.section {
  padding-bottom: 80px;
  padding-top: 80px;
}
.populated__image {
  padding: 80px 60px;
  background-color: #dbdedb;
}
.container {
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0 19px 38px rgb(0 0 0 / 10%), 0 15px 12px rgb(0 0 0 / 2%);
  position: relative;
}

.container .card {
  position: relative;

  height: 400px;
  background: #fdfbfb;
  border-radius: 20px;
  overflow: hidden;
}

// .container .card:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: #000000;
//   clip-path: circle(150px at 50% 41%);
//   transition: 0.5s ease-in-out;
// }

.container .card:hover:before {
  clip-path: circle(300px at 80% -20%);
}

// .container .card:after {
//   content: "Nike";
//   position: absolute;
//   top: 30%;
//   left: -20%;
//   font-size: 12em;
//   font-weight: 800;
//   font-style: italic;
//   color: rgba(255, 255, 255, 0.04);

// }

.container .card .imgBx {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

.container .card:hover .imgBx {
  top: 0%;
  transform: translateY(0%);
  /* bug  */
  img{
    height: 100%;
  }
}

.container .card .imgBx img {
  position: absolute;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%) rotate(0deg); */
  width: 100%;
  height: 335px;
  /* border-radius: 150px;*/
}

.container .card .contentBx {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  text-align: center;
  transition: 1s;
  z-index: 90;
  background: rgba(0, 0, 0, 0.432);
  color: white;
  h3 {
    font-family: monospace;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
  }
}

.container .card:hover .contentBx {
  height: 397px;
  z-index: 9999;
}

.container .card .contentBx h2 {
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(5, 5, 5);
}

.container .card .contentBx .size,
.container .card .contentBx .color {
  display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    line-height: 20px;
    width: 100%;
    text-align: center;
    margin-top: 5px;
}

.container .card:hover .contentBx .size {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card:hover .contentBx .read {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container .card .contentBx .size h3,
.container .card .contentBx .read h3 {
  color: white;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container .card .contentBx .size span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: #111;
  border-radius: 4px;
  cursor: pointer;
}

.container .card .contentBx .size span:hover {
  /* other bug */
  background: #b90000;
}

.container .card .contentBx .color span {
  width: 20px;
  height: 20px;
  background: #ff0;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.container .card .contentBx .color span:nth-child(2) {
  background: #1bbfe9;
}

.container .card .contentBx .color span:nth-child(3) {
  background: #1b2fe9;
}

.container .card .contentBx .color span:nth-child(4) {
  background: #080481;
}

.container .card .contentBx a {
  display: inline-block;
  padding: 10px 20px;
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #111;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
}

.container .card:hover .contentBx a {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.7s;
}
.cursor-pointer{
  cursor: pointer;
}
.image-container{
  width: 22vw;
  height: 50vh;
  margin-right: 1em;
  margin-top: 1em;
  @media(max-width:992px) {
    width: auto;
    margin-top: 1em;
  }
}
.harsh{
  font-size: 1.1em;
}

// procudt detail for the image container
.image-container {
  max-width: 100%; 
  height: auto; 

  @media (max-width: 320px) {
    max-width: 80%;
  }

  @media (max-width: 480px) {
    max-width: 90%;
  }
}

.cont-height{
  // max-width: 100%;
  height: 12em; 

  @media (max-width: 320px) {
    // max-width: 80%;
    // .sp-wdth{
      width: 100%;
    // }
  }

  @media (max-width: 480px) {
    // max-width: 90%;
    // .sp-wdth{
      width: 100%;
    // }
  }
}
// image responseve in the carousal
.responsive-image {
  height: 700px;
  @media (max-width: 768px) {
    height: 540px;
  }
  @media (max-width: 576px) {
    height: 337px;
  }
}

