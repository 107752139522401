.site-footer {
    padding: 80px 0;
    color: #000;
    background-color: #eae1dd;
    p{
        line-height: 29px;
        font-size: 17px;
        margin-bottom: 20px;
    }
}
.site-footer .custom-logo-link img {
    max-width: 80px;
    margin-bottom: 16px;
}
.site-footer a {
    color: #000;
    text-decoration: none;
}
.site-footer li{
    line-height: 33px;
}
.footer__address {
    margin-left: auto;
}
.site-footer h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    margin: 16px 0px 0px 0px
}