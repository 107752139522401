.header {
  display: flex;
  grid-template-areas: "logo nav";
  background-color: #000000;
  color: white;
  transition: all 0.3s ease-in-out;
  width: 100%;
  justify-content: space-between;
  z-index: 100;
  @media(max-width:992px) {
    .nav.navbar-nav.navbar-nav-scroll {
      display: block;
      text-align: center;
     
  }
  }
}
.header {
  position: sticky;
  top: 0;
  // background-color: #ffffff;
  z-index: 99999;
}

/* For desktop screens */
.inner--banner {
  background: #000000;
  padding: 4rem;
  display: flex;
  text-align: start;
  p {
    font-size: 20px;
  }
  h1 {
    font-size: 3.5em;
    text-align: left; /* Align left on desktop */
  }
  h2 {
    font-size: 40px;
  }
  @media (max-width: 1200px) {
    padding: 1rem;
  }
}

/* For tablet screens */
@media (max-width: 768px) {
  .inner--banner {
    flex-direction: column;
    align-items: center;
    text-align: center;
    p {
      font-size: 16px;
    }
    h1 {
      font-size: 2em;
      text-align: left; /* Align left on tablet */
    }
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .inner--banner {
    p {
      font-size: 14px;
    }
    h1 {
      font-size: 1.5em;
      text-align: left; /* Align left on mobile */
    }
    h2 {
      font-size: 30px;
    }
  }
}


.hp{
  width: 500px;
  @media(max-width:992px) {
    width: auto;
  }
}
.navbar-toggler-icon {
  background-image: url(../../../public/assets/images/3bars.jpg);
  border-radius: 50%;
}
.nav-link:focus, .nav-link:hover {
  color: white;
}
.nav-link:hover {
  color: white;
}
.navbar-nav-scroll {
  max-height: var(--bs-scroll-height,75vh);
  overflow: hidden;
}
.nav {
  grid-area: nav;
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: end;
  width: 100%;
  a {
    font-size: 17px;
    text-transform: uppercase;
  }
}
.is-sticky {
  background-color: white;
  color: black;
  position: fixed;
  top: 0;
  z-index: 99999;
  backdrop-filter: blur(8px);
  width: 100%;
  a:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: rgb(10, 10, 10)!important;
    transition: width .3s;
    opacity: 0;
    position: absolute;
    left: 0;
    bottom: -4px;
  }
  a:hover:after {
    width: 100%;
    opacity: 1;
  }
 
  a {
   
    position: relative;
    color: black!important;
  }
}
.nav  a:after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #fff;
  transition: width .3s;
  opacity: 0;
  position: absolute;
  left: 0;
  bottom: -4px;
}
.nav a:hover:after {
  width: 100%;
  opacity: 1;
}

.nav a {
  
  position: relative;
  color: white;
}
.logo {
  width: 3em;
  margin-left: 66px;
  @media(max-width:1200px) {
    margin-left: 10px;
}
}