// .inner--banner{
//     background: #000000;
//     padding: 4rem;
//     display: flex;
//     text-align: start;
//     p{
//         font-size: 20px;
//     }
//     h1{
//         // font-size: 55px;
//         font-size: 3.5em;
//     }
//     h2{
//         font-size: 40px;
//     }
//     @media(max-width:1200px) {
//        padding: 1rem;
//       }
// }
.inner--banner {
    background: #000000;
    padding: 4rem;
    display: flex;
    text-align: start;
    p {
      font-size: 20px;
    }
    h1 {
      font-size: 3.5em;
    }
    h2 {
      font-size: 40px;
    }
    @media (max-width: 1200px) {
      padding: 1rem;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      p {
        font-size: 16px;
      }
      h1 {
        font-size: 2em;
      }
    }
  }
  
.section--services {
    text-align: center;
}
.section--services .info {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
}
.services__block {
    background-color: #eae1dd;
    height: 250px;
    padding: 24px;
    color: #000;
    img {
        max-width: 60px;
        margin: auto;
        margin-bottom: 16px;
    }
    h4{
        margin-bottom: 24px;
    }
    p{
        text-align: center;
    }
}
.section--vision span {
    font-family: "Cormorant Garamond" ,Sans-serif;
    font-style: italic;
    font-size: 40px;
}
.section--vision p {
    font-size: 18px;
}